<template>
  <div>
    <v-container>
      <v-card class="mx-auto col-md-12 col-sm-12 col-xs-12 pa-0">
        <v-card-title class="d-flex justify-space-around pa-0">
          <h1 class="font-weight-medium text-center">{{ params.title }}</h1>
        </v-card-title>
      </v-card>
    </v-container>

    <v-container>
      <v-card class="mx-auto col-md-12 col-sm-12 col-xs-12">
        <v-card-text>
          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Número de identificaion de la petición"
                >1. Número de identificación de la petición:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details
                type="text"
                :disabled="true"
                v-model="form.nro_rad_frpqr"
                :error="error.nro_rad_frpqr"
                @change="error.nro_rad_frpqr = false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Fecha de radicación de la petición"
                >2. Fecha de radicación de la petición:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-menu
                v-model="date_radicado.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_radicado.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_radicado.value"
                  @input="date_radicado.estado = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Medio de llegada"
                >3. Medio de llegada:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                type="text"
                v-model="form.m_entr_frpqr"
                :error="error.m_entr_frpqr"
                @change="error.m_entr_frpqr = false"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Tipo de petición"
                >4. Tipo de petición:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                type="text"
                :disabled="true"
                v-model="form.tpq_nmbre"
                :error="error.tpq_nmbre"
                @change="error.tpq_nmbre = false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Entidad afectada, cuestionada o requerida"
                >5. Entidad afectada, cuestionada o requerida:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                type="text"
                v-model="item.ent_afec_frpqr"
                :error="error.ent_afec_frpqr"
                @change="error.ent_afec_frpqr = false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Fecha limite de respuesta con base en a fecha de llegada"
                >6. Fecha límite de respuesta con base en la fecha de
                llegada:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-menu
                v-model="date_limit_respuesta.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_limit_respuesta.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    clearable
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_limit_respuesta.value"
                  @input="date_limit_respuesta.estado = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Entidad competente para resolver la petición"
                >7. Entidad competente para resolver la petición:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                type="text"
                v-model="item.ent_resp_frpqr"
                :error="error.ent_resp_frpqr"
                @change="error.ent_resp_frpqr = false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Fecha de traslado por competencia"
                >8. Fecha de traslado por competencia:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-menu
                v-model="date_limit_traslado.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_limit_traslado.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_limit_traslado.value"
                  @input="date_limit_traslado.estado = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Nombre de la otra entidad que interviene"
                >9. Nombre de la otra entidad que interviene:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                type="text"
                v-model="item.ent_int_frpqr"
                :error="error.ent_int_frpqr"
                @change="error.ent_int_frpqr = false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Dependencia responsable del tramite"
                >10. Dependencia responsable del trámite:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-text-field
                outlined
                dense
                hide-details
                clearable
                type="text"
                :disabled="true"
                v-model="form.dep_nmbre"
                :error="error.dep_nmbre"
                @change="error.dep_nmbre = false"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Fecha de comunicación de ampliación de terminos"
                >11. Fecha de comunicación de ampliación de términos:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-menu
                v-model="date_notificacion.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_notificacion.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_notificacion.value"
                  @input="date_notificacion.estado = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Fecha de respuesta de fondo"
                >12. Fecha de respuesta de fondo:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-menu
                v-model="date_respuesta.estado"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date_respuesta.value"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date_respuesta.value"
                  @input="date_respuesta.estado = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex align-center" cols="12" xs="12" sm="6" md="6">
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Estado de tramite al final del periodo rendido"
                >13. Estado de trámite al final del periodo rendido:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="6" md="6">
              <v-select
                :items="[
                  { value: 1, text: 'Abierto' },
                  { value: 2, text: 'Cerrado' },
                ]"
                outlined
                dense
                hide-details
                v-model="item.est_tram_frpqr"
                :error="error.est_tram_frpqr"
                @change="error.est_tram_frpqr = false"
              ></v-select>
            </v-col>
          </v-row>

          <!--  -->

          <v-row>
            <v-col
              class="d-flex align-center"
              cols="12"
              xs="12"
              sm="12"
              md="12"
            >
              <span
                class="text-subtitle-2"
                :style="{ color: 'initial !important' }"
                title="Observación de la petición"
                >14. Observación:</span
              >
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="12">
              <v-textarea
                filled
                auto-grow
                rows="5"
                row-height="25"
                hide-details
                v-model="item.observ_frpqr"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row class="d-flex justify-space-around text-center">
            <v-col class="pb-2">
              <v-btn
                class="mx-3 white--text blue-bootstrap"
                @click="checKForm(true)"
              >
                {{ params.buttontext }}
              </v-btn>
            </v-col>
            <v-col class="pb-2">
              <v-btn
                class="mx-3 white--text green-bootstrap"
                @click="checKForm(false)"
              >
                Guardar
              </v-btn>
            </v-col>
            <v-col class="pb-2">
              <v-btn class="mx-3 red-bootstrap white--text" @click="cancel">
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    />
  </div>
</template>

<script>
import async from "async";
import ModalForm from "../modal/ModalForm.vue";
import { addDaysWithoutHolidays } from "../../utils";

export default {
  components: {
    ModalForm,
  },

  data: () => ({
    tab: null,
    close: false,

    params: {
      add: true,
      title: "",
      buttontext: "",
    },
    loader: {
      estado: false,
      message: "",
    },

    form: {
      nro_rad_frpqr: null,
      dep_nmbre: null,
      tpq_nmbre: null,
      time_response: null,
    },

    item: {
      ent_afec_frpqr: null,
      f_limit_resp_frpqr: null,
      ent_resp_frpqr: null,
      f_limit_tras_frpqr: null,
      ent_int_frpqr: null,
      f_comunic_frpqr: null,
      f_resp_frpqr: null,
      est_tram_frpqr: null,
      observ_frpqr: null,
    },

    error: {
      nro_rad_frpqr: false,
      tpq_nmbre: false,
      m_entr_frpqr: false,
      ent_afec_frpqr: false,
      f_limit_resp_frpqr: false,
      ent_resp_frpqr: false,
      f_limit_tras_frpqr: false,
      ent_int_frpqr: false,
      dep_nmbre: false,
      f_comunic_frpqr: false,
      f_resp_frpqr: false,
      est_tram_frpqr: false,
      observ_frpqr: false,
    },

    date_radicado: {
      estado: false,
      value: null,
    },
    date_limit_respuesta: {
      estado: false,
      value: null,
    },
    date_limit_traslado: {
      estado: false,
      value: null,
    },
    date_respuesta: {
      estado: false,
      value: null,
    },
    date_notificacion: {
      estado: false,
      value: null,
    },
    doc: {
      frcl_cdgo_dane: null,
      frcl_id_rad_rec: null,
      frcl_fc_rad: null,
      frcl_fc_rpt: null,
      frcl_id_rad_rpt: null,
    },
    dnl: [],
  }),
  created() {
    this.validate_proccess();
  },

  methods: {
    validate_proccess() {
      let { id, add, dcm_id } = this.$route.query;
      this.params.add = String(add) == "true" ? true : false;

      if (dcm_id) {
        this.getDocData(dcm_id, (err) => {
          if (err) {
            alert("Error al consultar el documento relacionado.");
          } else {
            this.getIdByDoc(dcm_id, (err, _id) => {
              if (err) {
                alert("Error al consultar la reclamación");
              } else if (_id) {
                this.params.add = false;
                this.getData(_id);
              } else {
                this.params.add = true;
                this.getData(_id);
              }
            });
          }
        });
      } else {
        this.getData(id);
      }
    },
    getDocData(id, callback) {
      let uri = "/docs/frpqr/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          let nro_rad_frpqr = response.data.nro_rad_frpqr || null;
          let f_rad_frpqr = response.data.f_rad_frpqr || null;
          let f_resp_frpqr = response.data.f_resp_frpqr || null;
          let dep_nmbre = response.data.dep_nmbre || null;
          let tpq_nmbre = response.data.tpq_nmbre || null;
          let tdo_nmbre = response.data.tdo_nmbre || null;
          let time_response = response.data.time_response || null;

          this.form.nro_rad_frpqr = nro_rad_frpqr;
          this.form.dep_nmbre = dep_nmbre;
          this.form.tpq_nmbre = tpq_nmbre;

          this.form.m_entr_frpqr = tdo_nmbre;
          this.form.time_response = time_response;

          this.date_radicado.value = this.formatDate(f_rad_frpqr);
          if (f_resp_frpqr) {
            this.date_respuesta.value = this.formatDate(f_resp_frpqr);
          }

          this.fetchDianol(async (err) => {
            if (err) {
              alert("Error al consultar los días no laborales.");
              return false;
            } else {
              let endDate = await addDaysWithoutHolidays({
                date_start: this.date_radicado.value,
                days: this.form.time_response,
                dnl: JSON.parse(JSON.stringify(this.dnl)),
              });

              this.date_limit_respuesta.value = endDate;

              return callback(false);
            }
          });
        })
        .catch((error) => {
          console.log("Error get data doc: ", error);
          this.$emit("msj", { text: error.message });
          return callback(true);
        });
    },
    getData(id) {
      if (this.params.add) {
        this.params.title = "Pqrs Contraloría";
        this.params.buttontext = "Agregar Pqrs";
      } else {
        this.params.title = "Actualizar Pqrs";
        this.params.buttontext = "Actualizar Pqrs";

        this.getItem(id);
        this.loader.estado = true;
        this.loader.message =
          "Por favor espere mientras se consultan los datos..";
      }
    },
    getItem(id) {
      let uri = "/formatopqrs/edit/" + id;

      this.axios
        .get(uri)
        .then(async (response) => {
          let f_limit_tras_frpqr = response.data.f_limit_tras_frpqr || null;
          let f_comunic_frpqr = response.data.f_comunic_frpqr || null;
          let f_resp_frpqr = response.data.f_resp_frpqr || null;

          this.date_limit_traslado.value = this.formatDate(f_limit_tras_frpqr);
          this.date_notificacion.value = this.formatDate(f_comunic_frpqr);
          this.date_respuesta.value = this.formatDate(f_resp_frpqr);

          this.loader.estado = false;

          this.item = response.data;

          let { dcm_id } = this.$route.query;

          if (!dcm_id) this.getDocData(this.item.dcm_id, () => {});
        })
        .catch((error) => {
          console.log("Error get item: ", error);
          this.$emit("msj", { text: error.message });
        });
    },
    getIdByDoc(id, callback) {
      let uri = "/formatopqrs/findbydoc/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          if (response.data) {
            return callback(false, response.data._id);
          } else {
            return callback(false, null);
          }
        })
        .catch((error) => {
          console.log(error);
          return callback(true, null);
        });
    },

    async checKForm(close) {
      let data = this.item;
      let form = this.form;

      if (!form.nro_rad_frpqr) {
        alert("Nro es obligatorio");
      } else if (!this.date_radicado.value) {
        alert("Fecha es obligatorio");
      } else if (!form.m_entr_frpqr) {
        this.error.m_entr_frpqr = true;
        alert("Medio de llegada es obligatorio");
      } else if (!form.tpq_nmbre) {
        alert("Tipo de peticion es obligatorio");
      } else if (!data.ent_afec_frpqr) {
        this.error.ent_afec_frpqr = true;
        alert("Entidad afectada es obligatorio");
      } else if (!this.date_limit_respuesta.value) {
        alert("Fecha limite de espuesta es obligatorio");
      } else if (!data.ent_resp_frpqr) {
        this.error.ent_resp_frpqr = true;
        alert("Entidad a resolver es obligatorio");
      } else if (!this.date_limit_traslado.value) {
        alert("Fecha limite de traslado es obligatorio");
      } else if (!data.ent_int_frpqr) {
        this.error.ent_int_frpqr = true;
        alert("Entidad a intervenir es obligatorio");
      } else if (!form.dep_nmbre) {
        alert("Dependencia responsable es obligatorio");
      } else if (!this.date_notificacion.value) {
        alert("Fecha de comunicación es obligatorio");
      } else if (!this.date_respuesta.value) {
        alert("Fecha de respuesta es obligatorio");
      } else if (!data.est_tram_frpqr) {
        this.error.est_tram_frpqr = true;
        alert("Estado trasmite es obligatorio");
      } else if (!data.observ_frpqr) {
        alert("Observaciones es obligatorio");
      } else {
        this.close = close;

        this.getDates();

        if (this.params.add) this.createFormatoPqr();
        else this.updateFormatoPqr();
      }
    },

    createFormatoPqr() {
      this.item.cmp_id = String(this.$store.state.company);
      this.item.dcm_id = String(this.$route.query.dcm_id);
      this.axios
        .post("/formatopqrs/add", this.item)
        .then(() => {
          this.params.add = false;

          if (this.close) this.cancel();
          else this.validate_proccess();

          //alert("Registro guardado");
        })
        .catch((error) => {
          console.log("Error guardando: ", error);
          this.$emit("msj", { text: error.message });
        });
    },

    updateFormatoPqr() {
      let uri = "/formatopqrs/update/" + this.item._id;

      this.axios
        .put(uri, this.item)
        .then(() => {
          if (this.close) this.cancel();
          //alert("Registro actualizado");
        })
        .catch((error) => {
          console.log("Error actualizando:", error);
          this.$emit("msj", { text: error.message });
        });
    },

    getDates() {
      let f_limit_resp_frpqr = null;
      let f_limit_tras_frpqr = null;
      let f_comunic_frpqr = null;
      let f_resp_frpqr = null;

      try {
        let {
          date_limit_respuesta,
          date_limit_traslado,
          date_notificacion,
          date_respuesta,
        } = this;

        if (date_limit_respuesta.value) {
          f_limit_resp_frpqr = new Date(
            date_limit_respuesta.value
          ).toISOString();
        }

        if (date_limit_traslado.value) {
          f_limit_tras_frpqr = new Date(
            date_limit_traslado.value
          ).toISOString();
        }

        if (date_notificacion.value) {
          f_comunic_frpqr = new Date(date_notificacion.value).toISOString();
        }

        if (date_respuesta.value) {
          f_resp_frpqr = new Date(date_respuesta.value).toISOString();
        }
      } catch (error) {
        console.log(error);
      }

      this.item.f_limit_resp_frpqr = f_limit_resp_frpqr;
      this.item.f_limit_tras_frpqr = f_limit_tras_frpqr;
      this.item.f_comunic_frpqr = f_comunic_frpqr;
      this.item.f_resp_frpqr = f_resp_frpqr;
    },

    formatDate(date) {
      if (!date) return null;
      else {
        let fecha = date.substring(0, 10);

        if (fecha == "1970-01-01") return null;
        else return fecha;
      }
    },

    cancel() {
      if (!this.$route.query.formatoPqrs) {
        this.$router.replace({
          name: "CreateDoc",
          params: {
            id: this.$route.query.dcm_id,
            add: false,
            search: this.$route.query.search,
          },
        });
      } else {
        this.$router.push({ name: "DisplayFormatoPqrs" });
      }
    },

    async fetchDianol(callback) {
      var data = {
        cmp_id: this.$store.state.company,
        inicio: this.date_radicado.value,
        fin: await this.addDays(this.date_radicado.value),
      };

      let uri = "/dianols/rango";
      this.axios
        .post(uri, data)
        .then((response) => {
          this.dnl = response.data;
          return callback(false);
        })
        .catch((err) => {
          return callback(true);
        });
    },

    async addDays(startDate) {
      startDate = new Date(startDate.replace(/-/g, "/"));

      let endDate = "";
      let count = 0;
      let noOfDaysToAdd = this.form.time_response * 2;

      while (count < noOfDaysToAdd) {
        endDate = new Date(startDate.setDate(startDate.getDate() + 1));

        if (endDate.getDay() != 0 && endDate.getDay() != 6) {
          count++;
        }
      }

      return endDate.toLocaleDateString().split("/").reverse().join("-");
    },
  },
};
</script>